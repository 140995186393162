import logo from './logo.svg';
import './App.css';
import LetsUnboundLandingPage from './RoutesContainer/RouteContainer';

function App() {
  return (
    <>
          <LetsUnboundLandingPage />
    </>
  );
}

export default App;
