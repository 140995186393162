import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import { Image } from 'antd';
import Container from '../../common/components/UI/Container';
import PartnerSectionWrapper from './pricing.style';
import Partner from '../../common/assets/iullustration.png';

const PricingSection = ( {
    row,
    col,
    title,
    description,
    button,
    textArea,
    imageArea,
} ) => {

    const handleProceedToTest = () => {
        window.open( 'https://myjourney.letsunbound.com/letsunbound/test/signup', '_blank' );
    }

    return (
        <PartnerSectionWrapper>
            <Container>
                <Box { ...row }>
                    <Box { ...col } { ...imageArea }>
                        <Image src={ Partner } alt="Domain Image" preview={ false } />
                    </Box>
                    <Box { ...col } { ...textArea }>
                        <Heading
                            { ...title }
                            content="Here's all you need to know"
                        />
                        <Text
                            { ...description }
                            content={ <>➔ <strong>For Classes:</strong> 6th to 12th</> }
                        />
                        <Text
                            { ...description }
                            content={ <>➔ <strong>Assessment Provisions: </strong>Assessment Conduction, Results and Report, Expert Counseling </> }
                        />
                        <Text
                            { ...description }
                            content={ <>➔ <strong>Assessment Sections: </strong>Personality, Career Interest, & Aptitude</> }
                        />
                        <Text
                            { ...description }
                            content={ <>➔ <strong>Assessment Duration: </strong>100 Mins for classes 6th-10th  and 110 Mins for classes 11th&12th</> }
                        />
                        <Text
                            { ...description }
                            content={ <>➔ <strong>Number of Questions: </strong>124 for class 6th - 10th and 134 for 11th&12th</> }
                        />
                        <Text
                            { ...description }
                            content={ <>➔ <strong>Question Type: </strong> Dichotomous and MCQ</> }
                        />
                        <Box>
                            <Button { ...button } title="Start Your Assessment!" onClick={ handleProceedToTest } />
                        </Box>
                    </Box>
                </Box>
            </Container>
        </PartnerSectionWrapper>
    );
};

PricingSection.propTypes = {
    row: PropTypes.object,
    col: PropTypes.object,
    title: PropTypes.object,
    description: PropTypes.object,
    button: PropTypes.object,
    textArea: PropTypes.object,
    imageArea: PropTypes.object,
};

PricingSection.defaultProps = {
    row: {
        flexBox: true,
        flexWrap: 'wrap',
        ml: '-15px',
        mr: '-15px',
        alignItems: 'center',
    },
    imageAreaRow: {
        flexDirection: 'row-reverse',
    },
    col: {
        pr: '15px',
        pl: '15px',
    },
    textArea: {
        width: ['100%', '100%', '55%', '50%', '47%'],
        pt: '20px',
    },
    imageArea: {
        width: ['100%', '100%', '45%', '50%', '53%'],
        mb: ['40px', '40px', '0', '0', '0'],
    },
    title: {
        fontSize: ['26px', '30px', '30px', '48px', '48px'],
        fontWeight: '800',
        color: '#000000',
        letterSpacing: '-0.025em',
        mb: '20px',
        lineHeight: '1.25',
    },
    description: {
        fontSize: '16px',
        color: '#000000',
        lineHeight: '1.75',
        fontWeight: '600',
    },
    button: {
        type: 'button',
        fontSize: '14px',
        fontWeight: '700',
        borderRadius: '10px',
        pl: '22px',
        pr: '22px',
        mt: '37px',
        minWidth: '150px',
        color: '#FFFFFF',
        backgroundColor: '#3732E1',
    },
};

export default PricingSection;
