import styled from "styled-components";

const PartnerSectionWrapper = styled.section `
  padding: 60px 0;
  background: linear-gradient(90deg, #fbd067 0%, #fee3a0 100%);
  background-repeat: no-repeat;
  background-position: 25% center;

  @media (max-width: 990px) {
    padding: 100px 0;
  }
  @media (max-width: 767px) {
    padding: 80px 0;
  }
  @media (max-width: 575px) {
    padding: 60px 0;
    margin-top: 60px;
  }
`;

export default PartnerSectionWrapper;